.tours_section {
  padding: 50px 0px;
  background: var(--secondaryClr);
}


.call_us {
  background: url("../../assets/images/slider/Deathroad_Andes2Amazon\ .jpg") no-repeat;
  padding: 88px 0px;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
}

.call_us .title {
  color: var(--primaryClr);
  font-style: italic;
}

.call_us .heading {
  font-size: 50px;
  color: var(--white);
  line-height: 58px;
  text-align: left;
  font-weight: bold;
  padding: 5px 0 20px 0;
}

.call_us p {
  font-size: var(--subTitle);
  color: var(--white);
  line-height: 30px;
}

.call_us .secondary_btn {
  background: none;
  font-size: 18px;
  color: var(--white);
  display: inline-block;
  border: 2px solid var(--white);
  padding: 12px 8px;
  text-decoration: none;
}

.bounce {
  animation: bounce 2s infinite;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #00000054;
  z-index: -1;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@media only screen and (max-width: 1199px) {
  .call_us {
    overflow: hidden;
  }

  .call_us .heading {
    font-size: 28px;
    line-height: 38px;
    padding: 5px 0 10px 0;
  }
}

@media only screen and (max-width: 420px) {
  .call_us {
    overflow: hidden;
  }
  .call_us .heading {
    font-size: 22px;
  }
}

.gallery {
    background-color: var(--secondaryClr);
    overflow: hidden;
    padding: 50px 0px;
}

.gallery .styles_thumbpanel__1sa4E {
    z-index: 9999;
}

.gallery .styles_lightroom__1X2qE{
    position: fixed;
    overflow: hidden;
    z-index: 9999;
}
/* Estilos del Widget de WhatsApp */
.whatsapp-widget {
  position: fixed;
  bottom: 130px; /* Posición desde abajo */
  right: 18px; /* Posición desde la derecha */
  z-index: 1000; /* Prioridad visual sobre otros elementos */
  background-color: #25d366; /* Color de fondo WhatsApp */
  border-radius: 50%; /* Forma circular */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Sombra para resaltar */
  width: 60px; /* Tamaño del botón */
  height: 60px;
  display: flex; /* Centrado del contenido */
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Icono de mano al pasar el mouse */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animación suave */
}

.whatsapp-widget:hover {
  transform: scale(1.1); /* Aumenta el tamaño ligeramente al pasar el mouse */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* Mayor sombra */
}

.whatsapp-widget img {
  width: 35px; /* Tamaño del ícono */
  height: 35px;
  object-fit: contain; /* Asegura que la imagen mantenga proporciones */
}

/* Estilos del Widget de Messenger */
.messenger-widget {
  position: fixed;
  bottom: 200px; /* Posición desde abajo */
  right: 18px; /* Posición desde la derecha */
  z-index: 1000; /* Prioridad visual */
  background-color: #ffffff; /* Color de Messenger */
  border-radius: 50%; /* Forma circular */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Sombra para resaltar */
  width: 60px; /* Tamaño del botón */
  height: 60px;
  display: flex; /* Centrado del contenido */
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Icono de mano al pasar el mouse */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animación suave */
}

.messenger-widget:hover {
  transform: scale(1.1); /* Aumenta el tamaño ligeramente al pasar el mouse */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* Mayor sombra */
}

.messenger-widget img {
  width: 35px; /* Tamaño del ícono */
  height: 35px;
  object-fit: contain; /* Mantiene proporciones */
}

/* Responsividad para pantallas más pequeñas */
@media (max-width: 768px) {
  .whatsapp-widget {
    bottom: 90px; /* Ajusta la posición para pantallas más pequeñas */
    width: 50px;
    height: 50px;
  }

  .whatsapp-widget img {
    width: 30px; /* Reduce tamaño del ícono */
    height: 30px;
  }

  .messenger-widget {
    bottom: 150px; /* Ajusta la posición para pantallas más pequeñas */
    width: 50px;
    height: 50px;
  }

  .messenger-widget img {
    width: 30px; /* Reduce tamaño del ícono */
    height: 30px;
  }
}

/* Responsividad para pantallas extra pequeñas */
@media (max-width: 480px) {
  .whatsapp-widget {
    bottom: 70px;
    right: 12px; /* Reduce el margen derecho */
    width: 45px;
    height: 45px;
  }

  .messenger-widget {
    bottom: 120px;
    right: 12px; /* Reduce el margen derecho */
    width: 45px;
    height: 45px;
  }

  .whatsapp-widget img,
  .messenger-widget img {
    width: 25px;
    height: 25px;
  }
}

.gallery {
  background-color: #fffaf9;
  overflow: hidden;
  padding: 50px 0;
}

.gallery .styles_thumbpanel__1sa4E {
  z-index: 9999;
}

.gallery .styles_lightroom__1X2qE {
  position: fixed;
  overflow: hidden;
  z-index: 9999;
}
