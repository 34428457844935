.slider {
  position: relative;
  z-index: 99;
  max-height: 650px; 
  overflow: hidden;
}

.slider .carousel {
  position: relative;
}
.slider .carousel .carousel-item .carousel-caption {
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.slider .carousel .carousel-item .carousel-caption h5 {
  font-size: 50px;
  color: var(--white);
  line-height: 140%;
  text-align: center;
  font-weight: 900;
  letter-spacing: 2px;
}

.slider .carousel .carousel-item .carousel-caption .slider_des p {
  font-size: var(--subTitle);
  color: var(--white);
  line-height: 150%;
  text-align: center;
  font-weight: 500;
}


.slider .carousel::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider .carousel .carousel-control-prev,
.slider .carousel .carousel-control-next {
  z-index: 100;
  position: absolute;
  top: 45%;
  width: 55px;
  height: 55px;
  display: block;
  border-radius: 100%;
  transition: box-shadow 0.5ms;
  background: var(--primaryClr);
  opacity: 0.2;
}

.slider .carousel .carousel-control-prev:hover,
.slider .carousel .carousel-control-next:hover {
  box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0.267);
}

.slider .carousel .carousel-control-prev::after,
.slider .carousel .carousel-control-next::after,
.slider .carousel .carousel-control-prev::before,
.slider .carousel .carousel-control-next::before {
  content: "";
  position: absolute;
  left: 43%;
  background: var(--white);
  width: 0.4rem;
  min-width: 3px;
  border-radius: 3px;
  height: 34%;
}

.slider .carousel .carousel-control-prev {
  left: 40px;
}

.slider .carousel .carousel-control-prev::before {
  transform: rotate(45deg);
  top: 24%;
}

.slider .carousel .carousel-control-prev::after {
  transform: rotate(-45deg);
  bottom: 24%;
}

.slider .carousel .carousel-control-next {
  right: 40px;
}

.slider .carousel .carousel-control-next::before {
  left: auto;
  right: 43%;
  transform: rotate(-45deg);
  top: 24%;
}

.slider .carousel .carousel-control-next::after {
  left: auto;
  right: 43%;
  transform: rotate(45deg);
  bottom: 24%;
}

.slider .carousel .carousel-control-prev span,
.slider .carousel .carousel-control-next span {
  display: none;
}

@media only screen and (max-width: 991px) {
  .slider .carousel .carousel-item .carousel-caption {
    bottom: 18%;
  }

  .slider .carousel .carousel-item .carousel-caption h5 {
    font-size: 35px;
  }
  .slider .carousel .carousel-item .carousel-caption .slider_des p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .slider .carousel .carousel-item img {
    height: 350px;
    object-fit: fill;
  }

  .slider .carousel .carousel-item .carousel-caption {
    bottom: 22%;
    padding: 0 0 20px 0;
    z-index: 9;
  }

  .slider .carousel .carousel-item .carousel-caption h5 {
    font-size: 26px ;
  }

  .slider .carousel .carousel-item .carousel-caption h5 span {
    display: inline-block;
  }

  .slider .carousel .carousel-item .carousel-caption .slider_des p {
    font-size: 14px;
  }

  .slider .carousel .carousel-control-next,
  .slider .carousel .carousel-control-prev {
    width: 40px;
    height: 40px;
  }

}

@media only screen and (max-width: 420px) {

  .slider .carousel .carousel-item .carousel-caption {
    right: 3%;
    left: 3%;
    bottom: 16%;
    padding: 0 0 20px 0;
    z-index: 9;
  }

  .slider .carousel .carousel-control-next,
  .slider .carousel .carousel-control-prev {
    width: 30px;
    height: 30px;
   
    
  }

}/* texto fijo */
.fixed-text {
  position: absolute;
  z-index: 10;
  top: 40%; 
  left: 50%;
  transform: translate(-50%, -30%);
  text-align: center;
  color: var(--white);
}

.fixed-text h5 {
  font-size: 55px !important;
  color: var(--white);
  line-height: 140%;
  font-weight: 900;
  letter-spacing: 1.5px;
}

.fixed-text .sub_text {
  font-size: var(--subTitle);
  color: var(--white);
  line-height: 150%;
  font-weight: 500;
}

/* Mantiene las imágenes del carrusel igual */
.slider .carousel-item img {
  object-fit: cover;
  width: 100%;
  height: 650px; /* Ajusta la altura según sea necesario */
}
.heading {
  font-size: 1.25rem; /* Tamaño reducido para móviles */
}

.sub_text {
  font-size: 0.75rem; /* Ajuste de tamaño en dispositivos pequeños */
}

.search-container {
  position: absolute;
  top: 50%; /* Centrado vertical */
  left: 50%; /* Centrado horizontal */
  transform: translate(-50%, -50%); /* Centrado exacto */
  width: 40%;
  max-width: 300px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  z-index: 100; /* Para que esté sobre otros elementos */
}
