@import "~react-image-gallery/styles/css/image-gallery.css";

.filter_box{
    padding: 20px 30px 30px;
    border: 1px solid #e7e6e6;
}

.filter_box .accordion-item{
    border-bottom: 1px solid #e7e6e6 !important ;
}
.filter_box .accordion:last-child .accordion-item{
    border: none !important;
    
}
.filter_box .accordion-item,
.filter_box .accordion-item button
{
    background: none;
    border: none;
    border-radius: 0;
}

.filter_box .accordion-item button {
    border: none !important;
    font-size: 16px;
    font-weight: 600;
    padding: 16px 0px;
    box-shadow: none !important;
}

.filter_box .accordion-item button:focus{
    border: none;
    outline: none;
    box-shadow: none;
}

.filter_box .accordion-item  .accordion-body{
    padding: 0;
    margin-bottom: 15px;
}

.filter_box .accordion-item  .accordion-body .form-check {
    margin-bottom: 10px;
}
.filter_box .accordion-item  .accordion-body .form-check-input {
    width: 1.2em;
    height: 1.2em;
}
.filter_box .accordion-item  .accordion-body .form-check-label {
    margin-left: 8px;
    margin-top: 5px;
}

.filters {
    position: sticky;
    top:55px
}


/* tour detail */
.tour_details .image-gallery-thumbnail{
    width: 12.3%;
}

.tour_details .image-gallery-thumbnail:hover,
.tour_details .image-gallery-thumbnail.active {
    border-color: var(--primaryClr);
}

.tour_details .nav_bars {
    background-color: #f14b361a;
    padding: 8px;
}

.tour_details .nav_bars  .nav-link {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0px ;
    color:black
}

.tour_details .nav_bars .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.tour_details .nav-pills .nav-link.active, .tour_details .nav-pills .show>.nav-link{
    color: white;
    background-color: var(--primaryClr);
}

.tour_details  .list-group-item  {
  padding: 16px 0px;
  border: none;
  font-size: var(--bodyText);
  background:unset;
  line-height: 24px;
  border-bottom: 1px solid gainsboro;
}

.tour_details  .list-group-item strong {
    font-weight: bold;
}

.tour_details .accordion, 
.tour_details .accordion-item{
    border: none;
}
.tour_details .accordion-header button {
    background: #f5f5f6;
    border: none;
    border-radius: 8px;
}

.tour_details .accordion-header button:focus{
    border: none;
    outline: none;
    box-shadow: none !important;
}

.tour_details .card-info{
    background: #f5f5f6;
    border: none;
}

.tour_details .card-info i {
    color: var(--primaryClr);
}


