/* Contenedor del buscador */
.search-container {
  position: absolute;
  top: 60%; /* Ajusta el valor para mover el buscador más abajo */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%; /* Ajusta el ancho según sea necesario */
  max-width: 300px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-top: 40px; /* Añade un margen superior para más separación */
}

/* Estilos del input del buscador */
.search-input {
  padding: 10px 15px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #d45d0e;
  outline: none;
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.2);
}

.suggested-results {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.suggestion-item {
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 15px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.suggestion-item:hover {
  background-color: #f9f9f9;
  color: #007bff;
}
