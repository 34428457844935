body {
  background-image: url('assets/images/FONDO.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.primaryBtn{
  background: var(--primaryClr);
  padding: 8px 15px;
  font-size: var(--bodyText);
  color:var(--white);
  text-align: center;
  position: relative;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-weight: 500;
  border-radius: 4px;
  border: none ;
}

.primaryBtn:hover{
  transform: translateY(-3px);
}

.main_heading{
  margin-bottom: 30px;
}

.main_heading h1 {
  font-size: var(--heading);
  color:var(--black);
  font-weight: bold;
  font-family: var(--fontFamily1);

}



.slick_slider .slick-arrow  {
  z-index: 100;
  position: absolute;
  top: 45%;
  width: 55px;
  height: 55px;
  display: block;
  border-radius: 100%;
  transition: box-shadow 0.3ms;
  background: var(--primaryClr);
  opacity: 1;
}

.slick_slider .slick-arrow:hover {
  box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0.267);
  background-color: var(--white);
}



.slick_slider .slick-arrow::after,
.slick_slider .slick-arrow::before
{
  content: "";
  position: absolute;
  left: 43%;
  background: var(--white);
  width: 0.4rem;
  min-width: 3px;
  border-radius: 3px;
  height: 34%;
}

.slick_slider .slick-arrow:hover{
  background-color: var(--primaryClr);
}

.slick_slider .slick-arrow:focus{
  background-color: var(--primaryClr);
}

.slick_slider .slick-prev {
  left: -15px;
}

.slick_slider .slick-prev::before {
  transform: rotate(45deg);
  top: 24%;
}

.slick_slider .slick-prev::after {
  transform: rotate(-45deg);
  bottom: 24%;
}

.slick_slider .slick-next {
  right: -15px;
}

.slick_slider .slick-next::before {
  left: auto;
  right: 43%;
  transform: rotate(-45deg);
  top: 24%;
}

.slick_slider .slick-next::after {
  left: auto;
  right: 43%;
  transform: rotate(45deg);
  bottom: 24%;
}

.slick_slider .slick-prev span,
.slick_slider .slick-next span {
  display: none;
}


@media only screen and (max-width: 600px){
  .slick_slider .slick-arrow{
    display: none !important;
  }
}
